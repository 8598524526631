import useTranslation from "next-translate/useTranslation";

const Testimonial = () => {
  const { t } = useTranslation("testimonials");
  const testimonials = t('testimonials', {}, { returnObjects: true });

  return (
    <>
      {testimonials.slice(0, 2).map((item) => (
        <div className="testimonial-block" key={item.id}>
          <div className="inner-box">
            <h4 className="title">{item.feedback}</h4>
            <div className="text">{item.feedbackText}</div>
            <div className="info-box">
              <div className="thumb">
                <img src={item.avatar} alt="testimonial" />
              </div>
              <h4 className="name">{item.name}</h4>
              {/* <span className="designation">{item.designation}</span> */}
              <a className="url" href={`${item.url}?ref=headhunti.pl`}>{item.urlLabel}</a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Testimonial;
