
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from "next-translate/useTranslation";
import { NextSeo } from 'next-seo';
import Home from "../components/home";
// export const getStaticProps = async () => {
//   const CALENDLY_PERSONAL_TOKEN = process.env.CALENDLY_PERSONAL_TOKEN;
//   const headers = {
//     'Authorization': `Bearer ${CALENDLY_PERSONAL_TOKEN}`,
//     'Content-Type': 'application/json'
//   };
//   const currentDate = new Date();
//   const oneMonthFromNow = new Date();
//   oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
//   const firstDay = currentDate.toISOString();
//   const lastDay = oneMonthFromNow.toISOString();  
//   // Calendly API endpoint for listing events. Adjust as necessary.
//   const url = `https://api.calendly.com/scheduled_events?min_start_time=${firstDay}&max_start_time=${lastDay}&user=${process.env.CALENDLY_USER_ID}`;
//   try {
//     const response = await fetch(url, { headers });
//     if (!response.ok) throw new Error('Failed to fetch data');
//     const data = await response.json();
//     const numberOfMeetings = data.collection.length; // Adjust based on Calendly's response structure
//     const slotsLeft = Math.max(5 - numberOfMeetings, 0); // Assuming 5 slots available per month
//     return {
//       props: { slotsLeft },
//       revalidate: 60, // Optional, for ISR, if using getStaticProps
//     };
//   } catch (error) {
//     return {
//       props: { slotsLeft: null }
//     };
//   }
// }
const Index = () => {
    const { t, lang } = useTranslation();
    const canonicalURL = `https://headhunti.${lang === 'pl' ? 'pl' : 'pl/en'}`;
    return (<>
      <NextSeo locale={t('common:localeTerritory')} canonical={canonicalURL} openGraph={{
            url: canonicalURL,
            images: [
                {
                    url: `/images/logo_headhunti_og.png`,
                    width: 1200,
                    height: 630,
                    alt: t('title')
                }
            ]
        }}/>
      <Home />
    </>);
};
export default Index;

    async function __Next_Translate__getStaticProps__19103bf1dcf__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19103bf1dcf__ as getStaticProps }
  